var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.$t('Booking times & participant informations')}},[(_vm.currentItem.has_bookings)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.$t('Bookings already exist for this event. Therefore, some informations in this area can not be edited.'))+" ")])]):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Registration deadline'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Registration deadline')}},[_c('b-form-datepicker',_vm._b({attrs:{"id":"deadline","placeholder":_vm.$t('Registration deadline'),"date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"disabled":_vm.currentItem.has_bookings,"min":new Date().toISOString().substr(0, 10),"state":_vm.getValidationState(errors)},model:{value:(_vm.currentItem.deadline),callback:function ($$v) {_vm.$set(_vm.currentItem, "deadline", $$v)},expression:"currentItem.deadline"}},'b-form-datepicker',_vm.labels[_vm.locale] || {},false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('deadline'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.deadline[0])))]):_vm._e()],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Booking type')}},[_c('b-form-select',{attrs:{"options":_vm.bookingTypes,"placeholder":_vm.$t('Please select an Booking Type'),"disabled":_vm.editMode},on:{"change":_vm.moveTimeslots},model:{value:(_vm.currentItem.booking_type),callback:function ($$v) {_vm.$set(_vm.currentItem, "booking_type", $$v)},expression:"currentItem.booking_type"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Event duration'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Event duration')}},[_c('b-form-input',{attrs:{"state":_vm.getValidationState(errors),"id":"duration","placeholder":_vm.$t('Event duration in min/hours/Days'),"disabled":_vm.currentItem.has_bookings},model:{value:(_vm.currentItem.duration),callback:function ($$v) {_vm.$set(_vm.currentItem, "duration", $$v)},expression:"currentItem.duration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('duration'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.duration[0])))]):_vm._e()],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min_value:1","name":_vm.$t('Minimum number of participants')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Minimum number of participants')}},[_c('b-form-input',{attrs:{"state":_vm.getValidationState(errors),"type":"number","placeholder":_vm.$t('Minimum number of participants')},model:{value:(_vm.currentItem.min_members),callback:function ($$v) {_vm.$set(_vm.currentItem, "min_members", $$v)},expression:"currentItem.min_members"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('min_members'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.min_members[0])))]):_vm._e()],1)]}}])})],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"rules":("required|max_value:200 |min_value:" + (_vm.currentItem.min_members)),"name":_vm.$t('Maximum number of participants')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Maximum number of participants')}},[_c('b-form-input',{attrs:{"type":"number","state":_vm.getValidationState(errors),"id":"max_members","placeholder":_vm.$t('Maximum number of participants')},model:{value:(_vm.currentItem.max_members),callback:function ($$v) {_vm.$set(_vm.currentItem, "max_members", $$v)},expression:"currentItem.max_members"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('max_members'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.max_members[0])))]):_vm._e()],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }