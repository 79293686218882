<template>
  <b-card :title="$t('Location')">
    <b-row>
      <b-col md="6">
        <validation-provider #default="{ errors }" :name="$t('Event location')" rules="required">
          <b-form-group :label="$t('Event location')">
            <b-form-select v-model="currentItem.eventlocation" id="contract_easy" :options="eventLocationOptions"
              :state="getValidationState(errors)" />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="validate && Object.keys(validation).includes('eventlocation')" class="text-danger">{{
            $t(validation.eventlocation[0]) }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="currentItem.eventLocationOptions == 2" md="6">
        <validation-provider #default="{ errors }" :name="$t('Event location')"
          :rules="{required: currentItem.eventLocationOptions === 2}" vid="event_location">
          <b-form-group :label="$t('Event location')">
            <b-form-select v-model="currentItem.online_options" :options="onlineOptions" id="onlineOptions"
              :state="getValidationState(errors)" />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="validate && Object.keys(validation).includes('online_options')" class="text-danger">{{
            $t(validation.online_options[0]) }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="6">
        <validation-provider #default="{ errors }" :name="$t('Personal generated Link')"
          :rules="{required: currentItem.eventLocationOptions === 2}">
          <b-form-group v-if="currentItem.online_options == 2" :label="$t('Personal generated Link')">
            <b-form-input v-model="currentItem.online_link" id="online_link"
              :placeholder="$t('Personal generated Link')" :state="getValidationState(errors)" />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="validate && Object.keys(validation).includes('online_link')" class="text-danger">{{
            $t(validation.online_link[0]) }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

    </b-row>
    <div v-if="currentItem.eventlocation == 1">
      <div class="mb-2">
        <b-form-checkbox v-model="currentItem.handicapped" value="1" name="handicapped" class="custom-control-success">
          {{ $t('Handicapped accessible') }}
        </b-form-checkbox>
      </div>

      <validation-provider #default="{ errors }" :name="$t('Street')"
        :rules="{required_if:{value:(currentItem.eventlocation == 1)}}">
        <b-form-group :label="$t('Street')" class="mb-1">
          <b-form-input v-model="currentItem.street" id="street" :state="getValidationState(errors)"
            :placeholder="$t('Street')" />
          <small class="text-danger">{{ errors[0] }}</small>
          <small v-if="validate && Object.keys(validation).includes('street')" class="text-danger">{{
          $t(validation.street[0]) }}</small>
        </b-form-group>
      </validation-provider>

      <b-row>
        <b-col md="3" xl="3" class="mb-1">
          <validation-provider #default="{ errors }" :name="$t('Postcode')"
            :rules="{required_if:{value:(currentItem.eventlocation == 1)}}">
            <b-form-group :label="$t('Postcode')" class="mb-1">
              <b-form-input v-model="currentItem.postal" type="number" :maxlength="5" :minlength="5" id="postcode"
                :placeholder="$t('Postcode')" :state="getValidationState(errors)" />
              <small class="text-danger">{{ errors[0] }}</small>
              <small v-if="validate && Object.keys(validation).includes('postal')" class="text-danger">{{
              $t(validation.postal[0]) }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="9" xl="9" class="mb-1">
          <validation-provider #default="{ errors }" :name="$t('City')"
            :rules="{required_if:{value:(currentItem.eventlocation == 1)}}">
            <b-form-group :label="$t('City')">
              <b-form-input v-model="currentItem.city" id="city" :placeholder="$t('City')"
                :state="getValidationState(errors)" />
              <small class="text-danger">{{ errors[0] }}</small>
              <small v-if="validate && Object.keys(validation).includes('city')" class="text-danger">{{
              $t(validation.city[0]) }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

    </div>
  </b-card>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTags,
  BFormTextarea,
  BFormTimepicker,
  BLink,
  BMedia,
  BMediaAside,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from '@validations'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormTags,
    BFormSelect,
    BFormTimepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    currentItem: {
      type: Object,
      default: () => {
      },
    },
    validation: {
      type: Object,
      default: () => {
      },
    },
    validate: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      eventLocationOptions: [
        {
          value: 1,
          text: this.$t('On Site')
        },
        {
          value: 2,
          text: this.$t('Online')
        },
      ],
      loaded: true,
    }

  },

  mounted() {
  },

  methods: {
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null
    },
  },
}
</script>

<style>
</style>

