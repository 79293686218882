<template>
  <b-card :title="$t('Define your appointments')">
    <b-alert v-if="!currentItem.has_bookings" variant="warning" show>
      <div class="alert-body">
        {{ $t('You selected the booking type "dynamic appointments". Please define your appointments in this area.') }}
      </div>
    </b-alert>
    <b-alert v-else variant="danger" show>
      <div class="alert-body">
        {{ $t('Bookings already exist for this event. Therefore, this area can not be edited.') }}
      </div>
    </b-alert>
    <div ref="form" class="repeater-form visible-row" @submit.prevent="repeatTimeslot">
      <div v-for="(timeslot, index) in currentItem.timeslots" :id="timeslot.id" :key="index" ref="timeslots">
        <b-row class="position-relative pr-5">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" size="md"
            class="btn-icon position-absolute position-right-0 position-bottom-0 mr-1 mb-1"
            @click="removeDynamicTimeslot(index)" :disabled="currentItem.has_bookings">
            <feather-icon icon="Trash2Icon" />
          </b-button>
          <b-col cols="6" class="mt-1">
            <validation-provider #default="{ errors }" :name="$t('Topic')" rules="required">
              <b-form-group :label="$t('Topic of the appointment')">
                <b-form-input v-model="timeslot.topic" :placeholder="$t('What is the topic of the appointment?')"
                  :disabled="currentItem.has_bookings" :state="getValidationState(errors)" />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="validate && Object.keys(validation).includes('timeslot.topic')" class="text-danger">{{
                $t(validation['timeslot.topic'][0]) }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6" class="mt-1">
            <validation-provider #default="{ errors }" :name="$t('Minutes')" rules="required">
              <b-form-group :label="$t('Booking time in minutes')">
                <b-form-input v-model="timeslot.booking_time"
                  :placeholder="$t('How long will this appointment go for?')" type="number"
                  :disabled="currentItem.has_bookings" :state="getValidationState(errors)" />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="validate && Object.keys(validation).includes('timeslot.booking_time')"
                  class="text-danger">{{
                  $t(validation['timeslot.booking_time'][0]) }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <hr>
      </div>

    </div>
    <b-row>
      <b-col md="12" class="text-right">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="repeatDynamicTimeslot"
          :disabled="currentItem.has_bookings">
          <feather-icon icon="PlusIcon" />
          <span>{{ $t('Add timeslot') }}</span>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTags,
  BFormTextarea,
  BFormTimepicker,
  BLink,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider,Validator } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

import { heightTransition } from '@core/mixins/ui/transition'
import { mask } from 'vue-the-mask'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormTags,
    BFormSelect,
    BFormTimepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    mask,
  },

  mixins: [heightTransition],

  props: {
    currentItem: {
      type: Object,
      default: () => {
      },
    },
    validation: {
      type: Object,
      default: () => {
      },
    },
    validate: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      loaded: true,
      locale: 'de',
      timeslots: [],
      timeSlotDynamicNextId: 1,
      labels: {
        de: {
          labelPrevDecade: 'Vorheriges Jahrzehnt',
          labelPrevYear: 'Vorheriges Jahr',
          labelPrevMonth: 'Vorheriger Monat',
          labelCurrentMonth: 'Aktueller Monat',
          labelNextMonth: 'Nächster Monat',
          labelNextYear: 'Nächstes Jahr',
          labelNextDecade: 'Nächstes Jahrzehnt',
          labelToday: 'Heute',
          labelSelected: 'Ausgewähltes Datum',
          labelNoDateSelected: 'Kein Datum gewählt',
          labelCalendar: 'Kalender',
          labelNav: 'Kalendernavigation',
          labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren',
        },
      },
    }
  },

  mounted() {
    if (this.timeslots.length  > 0) {
      this.currentItem.timeslots = this.timeslots
    } else if (this.currentItem.timeslots.length == 0 || !this.currentItem.timeslots) {
      this.currentItem.timeslots = [];
      this.repeatTimeslot()
    }
    this.initTrHeight()
  },

  beforeUnmount() {
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    messageTranslate(value){
      return this.$t(value);
    },
    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },
    repeatDynamicTimeslot() {
      this.currentItem.timeslots.push({
        index: this.timeSlotDynamicNextId += this.timeSlotDynamicNextId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.timeslots[0].offsetHeight)
      })
    },
    removeDynamicTimeslot(index) {
      this.currentItem.timeslots.splice(index, 1)
      this.trTrimHeight(this.$refs.timeslots[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style>
</style>

