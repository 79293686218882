<template>
  <b-card :title="$t('Payment')">
    <b-alert v-if="currentItem.has_bookings" variant="danger" show>
      <div class="alert-body">
        {{ $t('Bookings already exist for this event. Therefore, some informations in this area can not be edited.') }}
      </div>
    </b-alert>
    <b-row>
      <b-col md="6">
        <ValidationProvider rules="" vid="free_event">
          <b-form-checkbox v-model="currentItem.free_event" name="free_event" class="custom-control-success mb-1"
            :disabled="currentItem.has_bookings" unchecked-value=0 @change="checkFreeEvent">
            {{ $t('Free Event') }}
          </b-form-checkbox>
        </ValidationProvider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group :label='$t("Payment type")'>
          <b-form-select v-model="currentItem.payment_type" :options="paymentTypeOptions"
            :disabled="currentItem.has_bookings">
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col v-if="currentItem.free_event ==0" md="6">
        <validation-provider #default="{ errors }" :name="$t('Credits')" rules="required|min_value:50">
          <b-form-group :label="$t('Credits')">
            <b-form-input v-model="currentItem.credits" type="number" id="credits" :placeholder="$t('Credits')"
              :disabled="currentItem.has_bookings" :state="getValidationState(errors)" />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="validate && Object.keys(validation).includes('credits')" class="text-danger">{{
            $t(validation.credits[0]) }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row v-if="false">
      <b-col md="6">
        <validation-provider #default="{ errors }" :name="$t('Booking/Payment method')" rules="required_if:free_event,0">
          <b-form-group :label="$t('Booking/Payment method')">
            <b-form-select v-model="currentItem.payment_method" :options="paymentMethods"
                           :placeholder="$t('Please select a Booking/Payment method')" :disabled="currentItem.has_bookings"
                           :state="getValidationState(errors)" rules="">
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="validate && Object.keys(validation).includes('payment_method')" class="text-danger">{{
                $t(validation.payment_method[0]) }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTags,
  BFormTextarea,
  BFormTimepicker,
  BLink,
  BRow,
} from 'bootstrap-vue'
import {  ValidationObserver, ValidationProvider } from 'vee-validate'

import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormTags,
    BFormSelect,
    BFormTimepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

  },

  props: {
    currentItem: {
      type: Object,
      default: () => {
      },
    },
    validation: {
      type: Object,
      default: () => {
      },
    },
    validate: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      loaded: true,
      min_value: 1,
      paymentTypeOptions: [
        {
          value: 1,
          text: this.$i18n.t('one time payable event')
        },
        {
          value: 2,
          text: this.$i18n.t('payment per participant')
        },
      ],
      paymentMethods: [
        {
          value: 1,
          text: this.$t('Whole event completely sold')
        },
        {
          value: 2,
          text: this.$t('Place sold at the event')
        },
      ],
    }

  },

  mounted() {
    this.free_event = this.currentItem.free_even;
  },

  methods: {
    checkFreeEvent(value){
      this.currentItem.free_event = value
    },
    getValidationState({
      dirty,
      validated,
      valid = null
    })
    {
      return dirty || validated ? valid : null
    },

  },

}
</script>

<style>
</style>

