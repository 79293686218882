var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.$t('Define your appointments')}},[(!_vm.currentItem.has_bookings)?_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.$t('You selected the booking type "fixed appointments". Please define your fixed appointments in this area.'))+" ")])]):_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.$t('Bookings already exist for this event. Therefore, this area can not be edited.'))+" ")])]),_c('div',{ref:"form",staticClass:"repeater-form visible-row",on:{"submit":function($event){$event.preventDefault();return _vm.repeatTimeslot.apply(null, arguments)}}},_vm._l((_vm.currentItem.timeslots),function(timeslot,index){return _c('div',{key:index,ref:"timeslots",refInFor:true,attrs:{"id":timeslot.id}},[_c('b-row',{staticClass:"position-relative pr-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon position-absolute position-right-0 position-bottom-0 mr-1 mb-1",attrs:{"variant":"danger","size":"md","disabled":_vm.currentItem.has_bookings},on:{"click":function($event){return _vm.removeTimeslot(index)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1),_c('b-col',{attrs:{"md":"12 mb-1"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Topic'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Topic of the appointment')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('What is the topic of the appointment?'),"disabled":_vm.currentItem.has_bookings,"state":_vm.getValidationState(errors)},model:{value:(timeslot.topic),callback:function ($$v) {_vm.$set(timeslot, "topic", $$v)},expression:"timeslot.topic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('timeslot.topic'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation['timeslot.topic'][0])))]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Start date'),"rules":"required","vid":"start_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Start Date')}},[_c('b-form-datepicker',_vm._b({staticClass:"datepicker",attrs:{"overflow":"visible","placeholder":_vm.$t('No Date selected'),"date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":_vm.locale,"disabled":_vm.currentItem.has_bookings,"state":_vm.getValidationState(errors),"min":new Date().toISOString().substr(0, 10)},model:{value:(timeslot.start_date),callback:function ($$v) {_vm.$set(timeslot, "start_date", $$v)},expression:"timeslot.start_date"}},'b-form-datepicker',_vm.labels[_vm.locale] || {},false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('timeslot.start_date'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation['timeslot.start_date'][0])))]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Start time'),"vid":"start_time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Start Time')}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],staticClass:"form-control",attrs:{"disabled":_vm.currentItem.has_bookings,"raw":false,"placeholder":"hh:mm","state":_vm.getValidationState(errors)},model:{value:(timeslot.start_time),callback:function ($$v) {_vm.$set(timeslot, "start_time", $$v)},expression:"timeslot.start_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('timeslot.start_time'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation['timeslot.start_time'][0])))]):_vm._e()],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"pr-5"},[_c('b-col',{staticClass:"mt-1",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('End date'),"rules":"required|dateChecker:@start_date","vid":"end_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('End Date')}},[_c('b-form-datepicker',_vm._b({staticClass:"datepicker",attrs:{"name":"date_limit","placeholder":_vm.$t('No Date selected'),"date-format-options":{ year: 'numeric', month: '2-digit', day: '2-digit' },"locale":_vm.locale,"disabled":_vm.currentItem.has_bookings,"state":_vm.getValidationState(errors),"min":timeslot.start_date},model:{value:(timeslot.end_date),callback:function ($$v) {_vm.$set(timeslot, "end_date", $$v)},expression:"timeslot.end_date"}},'b-form-datepicker',_vm.labels[_vm.locale] || {},false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('timeslot.end_date'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation['timeslot.end_date'][0])))]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('End Time'),"vid":"end_time","rules":"required|timeChecker:@start_time,@start_date,@end_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('End Time')}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],staticClass:"form-control",attrs:{"disabled":_vm.currentItem.has_bookings,"placeholder":"hh:mm","raw":false,"state":_vm.getValidationState(errors),"name":_vm.$t('endtime')},model:{value:(timeslot.end_time),callback:function ($$v) {_vm.$set(timeslot, "end_time", $$v)},expression:"timeslot.end_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('timeslot.end_time'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation['timeslot.end_time'][0])))]):_vm._e()],1)]}}],null,true)})],1)],1),_c('hr')],1)}),0),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"size":"sm","variant":"primary","disabled":_vm.currentItem.has_bookings},on:{"click":_vm.repeatTimeslot}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Add Timeslot')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }