var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.$t('Event image')}},[_c('b-media',{staticClass:"flex-column flex-md-row mb-3 mt-3",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[(!_vm.currentItem.image)?_c('b-img',{ref:"refPreviewElDefault",attrs:{"src":require('@/assets/images/facebookpost/post-placeholder.jpg'),"width":"170"}}):_vm._e(),(_vm.currentItem.image)?_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2  mb-md-0",attrs:{"src":_vm.currentItem.image.url,"width":"170"}}):_vm._e()],1),_c('b-media-body',[_c('div',{staticClass:"d-inline-block"},[_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif","placeholder":_vm.$t('Choose image')},on:{"input":_vm.addImage},model:{value:(_vm.previewImage),callback:function ($$v) {_vm.previewImage=$$v},expression:"previewImage"}}),_c('div',{staticClass:"mt-2"},[(_vm.currentItem.image)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.deleteEventImage.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('Delete media'))+" ")]):_vm._e()],1)],1)])],1),_c('b-modal',{attrs:{"id":"bv-modal-example","hide-footer":""}},[_c('div',{staticClass:"d-block text-center"},[(_vm.previewImage)?_c('cropper',{ref:"cropper",staticClass:"cropper cropper_custom",attrs:{"src":_vm.previewImage,"default-position":_vm.defaultPosition,"default-size":_vm.defaultSize,"image-restriction":_vm.restrictionType,"resize-image":{
                        adjustStencil: true,
                        touch: true,
                        mouse: true,
                      },"stencil-size":_vm.stencilSize,"stencil-props":{
                        movable: false,
                        resizable: true,
                        handlers: {},
                        lines: {},
                       handlers: {
			eastNorth: true,
			north: false,
			westNorth: true,
			west: false,
			westSouth: true,
			south: false,
			eastSouth: true,
			east: false,
		}
      }},on:{"change":_vm.updateSize}}):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"md-6"},[_c('div',[_c('feather-icon',{staticClass:"mt-1 zoom-button",attrs:{"icon":"ArrowUpIcon","size":"22"},on:{"click":function($event){return _vm.move('top')}}}),_c('feather-icon',{staticClass:"mt-1 ml-1 zoom-button",attrs:{"icon":"ArrowLeftIcon","size":"22"},on:{"click":function($event){return _vm.move('left')}}}),_c('feather-icon',{staticClass:"mt-1 ml-1 zoom-button",attrs:{"icon":"ArrowRightIcon","size":"22"},on:{"click":function($event){return _vm.move('right')}}}),_c('feather-icon',{staticClass:"mt-1 ml-1 zoom-button",attrs:{"icon":"ArrowDownIcon","size":"22"},on:{"click":function($event){return _vm.move('bottom')}}})],1)]),_c('b-col',{staticClass:"md-6 zoom-group d-flex justify-content-end"},[_c('feather-icon',{staticClass:" mt-1 zoom-button mb-2",attrs:{"icon":"ZoomInIcon","size":"22"},on:{"click":function($event){return _vm.zoom(1.2)}}}),_c('feather-icon',{staticClass:"mt-1 ml-1 mb-2 zoom-button",attrs:{"icon":"ZoomOutIcon","size":"22"},on:{"click":function($event){return _vm.zoom(0.8)}}})],1)],1),_c('div',[_c('span',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.$t('You can zoom in and out by using your mouse wheel. Each scroll, up or down, increases or decreases the zoom factor. Please center your Event Picture')))])]),_c('b-button',{staticClass:"mt-3",attrs:{"block":""},on:{"click":_vm.uploadImage}},[_vm._v(" "+_vm._s(_vm.$t('Save')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }