<template>
  <b-card :title="$t('General Event informations')">
    <b-row>
      <b-col md="6">
        <b-form-checkbox v-model="currentItem.status" value="1" name="status" class="custom-control-success">
          {{ $t('Active') }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="6">
        <validation-provider #default="{ errors }" :name="$t('Event Title')" rules="required">

          <b-form-group :label="$t('Event Title')">
            <b-form-input v-model="currentItem.name" :state="getValidationState(errors)" id="title"
              :placeholder="$t('Event Title')" />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="validate && Object.keys(validation).includes('name')" class="text-danger">{{
            $t(validation.name[0]) }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6">
        <validation-provider #default="{ errors }" :name="$t('Event language')" rules="required">
          <b-form-group :label="$t('Event language')">
            <b-form-select v-model="currentItem.language" :state="getValidationState(errors)" id="language"
              :options="languages" text-field="name" value-field="code" />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="validate && Object.keys(validation).includes('language')" class="text-danger">{{
            $t(validation.language[0]) }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <validation-provider #default="{ errors }" :name="$t('Event Type')" rules="required">
          <b-form-group :label="$t('Event Type')">
            <b-form-select v-model="currentItem.event_type_id" :options="eventTypesOptions"
              :placeholder="$t('Please select an Event Type')" :state="getValidationState(errors)">
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="validate && Object.keys(validation).includes('event_type_id')" class="text-danger">{{
            $t(validation.event_type_id[0]) }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Agenda')" class="mb-1">
          <b-form-input v-model="currentItem.agenda" id="street" :placeholder="$t('Agenda')" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group :label="$t('Target Group')" class="mb-1">
          <b-form-input v-model="currentItem.target" id="street" :placeholder="$t('Target Group')" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <validation-provider #default="{ errors }" :name="$t('Short Description')" rules="required|max:300">
          <b-form-group :label="$t('Short Description')">
            <b-form-textarea rows="6" :state="getValidationState(errors)" v-model="currentItem.informations"
              :placeholder="$t('Short Description')" />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="validate && Object.keys(validation).includes('informations')" class="text-danger">{{
            $t(validation.informations[0]) }}</small>
            <span>{{ charactersLeft }} </span>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTags,
  BFormTextarea,
  BFormTimepicker,
  BLink,
  BMedia,
  BMediaAside,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from '@validations'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {heightTransition} from '@core/mixins/ui/transition'
import {quillEditor} from 'vue-quill-editor'
import {mask} from 'vue-the-mask'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormTags,
    quillEditor,
    BFormSelect,
    BFormTimepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    currentItem: {
      type: Object,
      default: () => {
      },
    },
    validation: {
      type: Object,
      default: () => {
      },
    },
    validate: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      eventTypesOptions: [],
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
      },

      languages: [],

      loaded: true,
    }

  },

  mounted() {
    this.getEventTypes()
    this.getLanguages()


  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  computed: {
    charactersLeft() {
      if (this.currentItem.informations) {
        let char = this.currentItem.informations.length,
            limit = 300;
        return char + ' / ' + limit +' '+this.$t('characters remaining')
      }
    }
  },
  methods: {
    getEventTypes() {
      this.$http
          .get(`/eventtypes`)
          .then((response) => {
            let options = [
              {
                value: null,
                text: this.$i18n.t('Please select an event type')
              },
            ]
            response.data.items.forEach(function (item) {
              options.push({
                text: item.name,
                value: item.id,
              })
            })
            this.eventTypesOptions = options
            this.currentItem.event_type_id = (options.length > 1 ? options[1].value : null)
          })
          .catch((error) => {
          })
    },
    getLanguages() {
      this.$http.get('/languages').then(response => {
        this.languages = response.data.items
      })
    },
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null
    },
  },
}
</script>

<style>
.quill-editor .ql-container {
  height: 120px;
}
</style>

