<template>
  <b-card :title="$t('Define your appointments')">
    <b-alert v-if="!currentItem.has_bookings" variant="warning" show>
      <div class="alert-body">
        {{ $t('You selected the booking type "fixed appointments". Please define your fixed appointments in this area.')
        }}
      </div>
    </b-alert>
    <b-alert v-else variant="danger" show>
      <div class="alert-body">
        {{ $t('Bookings already exist for this event. Therefore, this area can not be edited.') }}
      </div>
    </b-alert>
    <div ref="form" class="repeater-form visible-row" @submit.prevent="repeatTimeslot">
      <div v-for="(timeslot, index) in currentItem.timeslots" :id="timeslot.id" :key="index" ref="timeslots">
        <b-row class="position-relative pr-5">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" size="md"
            class="btn-icon position-absolute position-right-0 position-bottom-0 mr-1 mb-1"
            @click="removeTimeslot(index)" :disabled="currentItem.has_bookings">
            <feather-icon icon="Trash2Icon" />
          </b-button>

          <b-col md="12 mb-1">
            <validation-provider #default="{ errors }" :name="$t('Topic')" rules="required">
              <b-form-group :label="$t('Topic of the appointment')">
                <b-form-input v-model="timeslot.topic" :placeholder="$t('What is the topic of the appointment?')"
                  :disabled="currentItem.has_bookings" :state="getValidationState(errors)" />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="validate && Object.keys(validation).includes('timeslot.topic')" class="text-danger">{{
                $t(validation['timeslot.topic'][0]) }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" :name="$t('Start date')" rules="required" vid="start_date">
              <b-form-group :label="$t('Start Date')">
                <b-form-datepicker v-model="timeslot.start_date" class="datepicker" overflow="visible"
                  :placeholder="$t('No Date selected')"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :locale="locale"
                  v-bind="labels[locale] || {}" :disabled="currentItem.has_bookings" :state="getValidationState(errors)"
                  :min="new Date().toISOString().substr(0, 10)" />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="validate && Object.keys(validation).includes('timeslot.start_date')" class="text-danger">{{
                $t(validation['timeslot.start_date'][0]) }}</small>
              </b-form-group>

            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" :name="$t('Start time')" vid="start_time" rules="required">
              <b-form-group :label="$t('Start Time')">
                <b-form-input :disabled="currentItem.has_bookings" :raw="false" v-mask="'##:##'" placeholder="hh:mm"
                  v-model="timeslot.start_time" :state="getValidationState(errors)" class="form-control" />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="validate && Object.keys(validation).includes('timeslot.start_time')" class="text-danger">{{
                $t(validation['timeslot.start_time'][0]) }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="pr-5">
          <b-col md="6" class="mt-1">
            <validation-provider #default="{ errors }" :name="$t('End date')" rules="required|dateChecker:@start_date"
              vid="end_date">
              <b-form-group :label="$t('End Date')">
                <b-form-datepicker name="date_limit" v-model="timeslot.end_date" class="datepicker"
                  :placeholder="$t('No Date selected')"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :locale="locale"
                  v-bind="labels[locale] || {}" :disabled="currentItem.has_bookings" :state="getValidationState(errors)"
                  :min="timeslot.start_date" />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="validate && Object.keys(validation).includes('timeslot.end_date')" class="text-danger">{{
                $t(validation['timeslot.end_date'][0]) }}</small>

              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6" class="mt-1">
            <validation-provider #default="{ errors }" :name="$t('End Time')" vid="end_time"
              rules="required|timeChecker:@start_time,@start_date,@end_date">
              <b-form-group :label="$t('End Time')">
                <b-form-input :disabled="currentItem.has_bookings" v-mask="'##:##'" placeholder="hh:mm"
                  v-model="timeslot.end_time" :raw="false" :state="getValidationState(errors)" class="form-control"
                  :name="$t('endtime')" />


                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="validate && Object.keys(validation).includes('timeslot.end_time')" class="text-danger">{{
                $t(validation['timeslot.end_time'][0]) }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <hr>
      </div>

    </div>
    <b-row>
      <b-col md="12" class="text-right">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="repeatTimeslot"
          :disabled="currentItem.has_bookings">
          <feather-icon icon="PlusIcon" />
          <span>{{ $t('Add Timeslot') }}</span>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTags,
  BFormTextarea,
  BFormTimepicker,
  BLink,
  BMedia,
  BMediaAside,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from '@validations'
import Ripple from 'vue-ripple-directive'

import { extend } from 'vee-validate';
import moment from 'moment'

extend('dateChecker', {
  params: ['target'],
  validate(value,  {target} ) {
    let start_date = target;
    let end_date = value;
    return  moment(start_date).isSameOrBefore(end_date);
  },
  message: 'End date must be at least the same date as the start date'
});
extend('timeChecker', {
  params: ['start_time', 'start_date', 'end_date'],
  validate(value, {start_time, start_date, end_date}) {
    let start = start_date + ' ' + start_time;
    let end = end_date + ' ' + value;
    return  moment(start).isSameOrBefore(end);
  },
  message: 'End time must be greater than start time'
});



import {heightTransition} from '@core/mixins/ui/transition'
import {mask} from 'vue-the-mask'
import {setDatePickerTranslation} from "@core/mixins/datePickerLocales";

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormTags,
    BFormSelect,
    BFormTimepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    mask,
  },

  mixins: [heightTransition,setDatePickerTranslation],

  props: {
    currentItem: {
      type: Object,
      default: () => {
      },
    },
    validation: {
      type: Object,
      default: () => {
      },
    },
    validate: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      loaded: true,
      timeslots: [],
      timeOption: {
        start_time: {
          time: true,
          timePattern: ['h', 'm'],
        },

        end_time: {
          time: true,
          timePattern: ['h', 'm'],
        },
      },

    }
  },

  mounted() {
    if (this.timeslots.length  > 0) {
      this.currentItem.timeslots = this.timeslots
    } else if (this.currentItem.timeslots.length == 0 || !this.currentItem.timeslots) {
      this.currentItem.timeslots = [];
      this.repeatTimeslot()
    }
    this.initTrHeight()
  },

  unmounted() {
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    messageTranslate(value){
      return this.$t(value);
    },
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null
    },
    repeatTimeslot() {
      this.currentItem.timeslots.push({
        index: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.timeslots[0].offsetHeight)
      })
    },
    removeTimeslot(index) {
      this.currentItem.timeslots.splice(index, 1)
      this.trTrimHeight(this.$refs.timeslots[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style>
</style>

