<template>
  <b-card :title="$t('Booking times & participant informations')">
    <b-alert v-if="currentItem.has_bookings" variant="danger" show>
      <div class="alert-body">
        {{ $t('Bookings already exist for this event. Therefore, some informations in this area can not be edited.') }}
      </div>
    </b-alert>
    <b-row>
      <b-col md="6">
        <validation-provider #default="{ errors }" :name="$t('Registration deadline')" rules="required">
          <b-form-group :label="$t('Registration deadline')">
            <b-form-datepicker id="deadline" v-model="currentItem.deadline" :placeholder="$t('Registration deadline')"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-bind="labels[locale] || {}"
              :disabled="currentItem.has_bookings" :min="new Date().toISOString().substr(0, 10)"
              :state="getValidationState(errors)" />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="validate && Object.keys(validation).includes('deadline')" class="text-danger">{{
            $t(validation.deadline[0]) }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group :label="$t('Booking type')">
          <b-form-select v-model="currentItem.booking_type" :options="bookingTypes"
            :placeholder="$t('Please select an Booking Type')" :disabled="editMode" @change="moveTimeslots">
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <validation-provider #default="{ errors }" :name="$t('Event duration')" rules="required">
          <b-form-group :label="$t('Event duration')">
            <b-form-input v-model="currentItem.duration" :state="getValidationState(errors)" id="duration"
              :placeholder="$t('Event duration in min/hours/Days')" :disabled="currentItem.has_bookings" />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="validate && Object.keys(validation).includes('duration')" class="text-danger">{{
            $t(validation.duration[0]) }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" xl="6" class="mb-1">
        <validation-provider #default="{ errors }" rules="required|min_value:1"
          :name="$t('Minimum number of participants')">
          <b-form-group :label="$t('Minimum number of participants')">
            <b-form-input v-model="currentItem.min_members" :state="getValidationState(errors)" type="number"
              :placeholder="$t('Minimum number of participants')" />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="validate && Object.keys(validation).includes('min_members')" class="text-danger">{{
            $t(validation.min_members[0]) }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="6" xl="6" class="mb-1">
        <validation-provider #default="{ errors }" :rules="`required|max_value:200 |min_value:${currentItem.min_members}`"
          :name="$t('Maximum number of participants')">
          <b-form-group :label="$t('Maximum number of participants')">
            <b-form-input type="number" :state="getValidationState(errors)" v-model="currentItem.max_members"
              id="max_members" :placeholder="$t('Maximum number of participants')" />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="validate && Object.keys(validation).includes('max_members')" class="text-danger">{{
            $t(validation.max_members[0]) }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTags,
  BFormTextarea,
  BFormTimepicker,
  BLink,
  BMedia,
  BMediaAside,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from '@validations'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import {mask} from 'vue-the-mask'
import * as rules from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import {setDatePickerTranslation} from "@core/mixins/datePickerLocales";
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormTags,
    BFormSelect,
    BFormTimepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    mask,
  },
  mixins:[setDatePickerTranslation],
  props: {
    currentItem: {
      type: Object,
      default: () => {
      },
    },
    validation: {
      type: Object,
      default: () => {
      },
    },
    editMode: {
      type: Boolean,
      default: () => false,
    },
    validate: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      loaded: true,
      fixed_timeslots: [],
      dynamic_timeslots: [],
      bookingTypes: [
        {
          value: 1,
          text: this.$t('Fixed Appointment')
        },
        {
          value: 2,
          text: this.$t('Dynamic appointment - chosen by the Customer')
        }
      ],
    }
  },

  mounted() {
  },

  methods: {
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null
    },
    moveTimeslots() {
      console.debug("update...");
      if (this.currentItem.booking_type == 1) {
        this.dynamic_timeslots = this.currentItem.timeslots
        this.currentItem.timeslots = this.fixed_timeslots
      }
      if (this.currentItem.booking_type == 2) {
        this.fixed_timeslots = this.currentItem.timeslots
        this.currentItem.timeslots = this.dynamic_timeslots
      }
    }
  },
}
</script>

<style>
</style>

