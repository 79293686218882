<template>
  <b-card :title="$t('Event image')">
    <b-media no-body vertical-align="center" class="flex-column flex-md-row mb-3 mt-3">
      <b-media-aside>
        <b-img v-if="!currentItem.image"
               ref="refPreviewElDefault"
               :src="require('@/assets/images/facebookpost/post-placeholder.jpg')"
               width="170"
        ></b-img>
        <b-img v-if="currentItem.image" ref="refPreviewEl" :src="currentItem.image.url" width="170"
               class="rounded mr-2  mb-md-0"
        />
      </b-media-aside>
      <b-media-body>
        <div class="d-inline-block">
          <b-form-file ref="refInputEl" v-model="previewImage" accept=".jpg, .png, .gif"
                       :placeholder="$t('Choose image')" @input="addImage"
          />
          <div class="mt-2">
            <b-button v-if="currentItem.image" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm"
                      variant="danger" @click.prevent="deleteEventImage"
            >
              {{ $t('Delete media') }}
            </b-button>
          </div>
        </div>
      </b-media-body>
    </b-media>

    <b-modal id="bv-modal-example" hide-footer>
      <div class="d-block text-center">
        <cropper
            ref="cropper"
            v-if="previewImage"
            class="cropper cropper_custom"
            :src="previewImage"
            :default-position="defaultPosition"
            :default-size="defaultSize"
            :image-restriction="restrictionType"
            @change="updateSize"
            :resize-image="{
                        adjustStencil: true,
                        touch: true,
                        mouse: true,
                      }"
            :stencil-size="stencilSize"
            :stencil-props="{
                        movable: false,
                        resizable: true,
                        handlers: {},
                        lines: {},
                       handlers: {
			eastNorth: true,
			north: false,
			westNorth: true,
			west: false,
			westSouth: true,
			south: false,
			eastSouth: true,
			east: false,
		}
      }"
        >
        </cropper>
      </div>

      <b-row>
        <b-col class="md-6">
          <div>
            <feather-icon @click="move('top')" icon="ArrowUpIcon" class="mt-1 zoom-button" size="22"/>
            <feather-icon @click="move('left')" icon="ArrowLeftIcon" class="mt-1 ml-1 zoom-button" size="22"/>
            <feather-icon @click="move('right')" icon="ArrowRightIcon" class="mt-1 ml-1 zoom-button" size="22"/>
            <feather-icon @click="move('bottom')" icon="ArrowDownIcon" class="mt-1 ml-1 zoom-button" size="22"/>
          </div>
        </b-col>
        <b-col class="md-6 zoom-group d-flex justify-content-end">
          <feather-icon @click="zoom(1.2)" icon="ZoomInIcon" class=" mt-1 zoom-button mb-2" size="22"/>
          <feather-icon @click="zoom(0.8)" icon="ZoomOutIcon" class="mt-1 ml-1 mb-2 zoom-button" size="22"/>
        </b-col>
      </b-row>

      <div>
        <span class="mt-1"
        >{{
            $t('You can zoom in and out by using your mouse wheel. Each scroll, up or down, increases or decreases the zoom factor. Please center your Event Picture')
          }}</span>
      </div>
      <b-button class="mt-3" block @click="uploadImage"> {{ $t('Save') }}</b-button>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BImg,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BModal,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
// import SquareButton from './Components/SquareButton';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import 'vue-advanced-cropper/dist/theme.compact.css'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BFormFile,
    Cropper,
    BModal,
    // SquareButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    currentItem: {
      type: Object,
      default: () => {
      },
    },
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      loaded: true,
      mediaFile: null,
      previewImage: null,
      imageName: null,
      restrictionType: 'none',
      stencilSize: {
        width: process.env.VUE_APP_EVENT_IMAGE_WIDTH,
        height: process.env.VUE_APP_EVENT_IMAGE_HEIGHT
      },
      size: {
        width: null,
        height: null,
      },
    }

  },

  methods: {
    defaultPosition() {
      return {
        left: 100,
        top: 100,
      }
    },
    zoom(factor) {
      this.$refs.cropper.zoom(factor)
    },
    move(direction) {
      if (direction === 'left') {
        this.$refs.cropper.move(-this.size.width / 16)
      } else if (direction === 'right') {
        this.$refs.cropper.move(this.size.width / 16)
      } else if (direction === 'top') {
        this.$refs.cropper.move(0, -this.size.height / 16)
      } else if (direction === 'bottom') {
        this.$refs.cropper.move(0, this.size.height / 16)
      }
    },
    updateSize({ coordinates }) {
      this.size.width = Math.round(coordinates.width)
      this.size.height = Math.round(coordinates.height)
    },
    defaultSize() {
      return {
        width: process.env.VUE_APP_VIDEO_NUGGET_POSTER_WIDTH,
        height: process.env.VUE_APP_VIDEO_NUGGET_POSTER_HEIGHT,
      }
    },
    aspectRatioCustom() {
      return process.env.VUE_APP_VIDEO_NUGGET_POSTER_RATIO
    },
    addImage() {
      if (event) event.preventDefault()
      const reader = new FileReader()
      let file = this.$refs.refInputEl.files[0]

      if (file) {
        reader.readAsDataURL(file)
        this.imageName = file.name
        reader.onload = (evt) => {
          let base64 = evt.target.result
          this.previewImage = base64
          this.$bvModal.show('bv-modal-example')
        }
      }
    },
    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult()
      const formData = new FormData()
      canvas.toBlob(async blob => {
        formData.append(
            'files',
            blob,
            this.imageName)

        let url = `/events/${this.$route.params.eventid}/imageupload`
        this.$http
            .post(url, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              this.currentItem.image = response.data.image
              this.$bvModal.hide('bv-modal-example')

              this.$bvToast.toast(this.$i18n.t('Image uploaded successfully'), {
                title: this.$i18n.t('Success'),
                variant: 'success',
                toaster: 'b-toaster-bottom-right',
              })
            })
            .catch((e) => {
              this.$bvToast.toast(this.$i18n.t('Image not uploaded'), {
                title: this.$i18n.t('Error'),
                variant: 'danger',
                toaster: 'b-toaster-bottom-right',
              })
            })
      })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

    deleteEventImage() {
      let url = `/events/${this.$route.params.eventid}/imageremove`

      this.$http
          .delete(url)
          .then((response) => {
            this.currentItem.image = null

            this.$bvToast.toast(this.$i18n.t('Image deleted'), {
              title: this.$i18n.t('Success'),
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
            })
          })
          .catch((e) => {
            this.$bvToast.toast(this.$i18n.t('Image not deleted'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
            })
          })
    },

  },
}
</script>

<style lang="scss">
.vue-advanced-cropper__background {
  //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
  background: #DDD;
}

.preview-result-example {
  display: flex;

  &__cropper {
    width: 300px;
  }

  &__previews {
    margin-left: 32px;
  }

  &__preview {
    border-radius: 50%;
    overflow: hidden;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100px;
    height: 100px;
    border: 1px solid #c4c3c3;

    &--small {
      width: 60px;
      height: 60px;
    }
  }

  &__preview-image {
    width: 100%;
  }

  &__button {
    position: absolute;
    left: 16px;
    bottom: 0;
  }
}

.vue-rectangle-stencil {
  background: #ccc;
}

.vertical-buttons {
  position: initial;
  left: 2px;
  top: 80%;
  transform: translateY(-50%);
}

.square-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background 0.5s;

  &:hover {
    background: #DDD;
  }
}

.zoom-button {
  display: inline;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  //margin-bottom: 5px;
  cursor: pointer;
  transition: background 0.5s;

  &:hover {
    background: #DDD;
  }
}

.zoom-group {
  display: flex;
}
</style>

