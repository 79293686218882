<template>
  <b-card :title="$t('Cancellation policy')">
    <div v-if="currentItem.free_event == 1">
      <b-alert v-if="!currentItem.has_bookings" variant="warning" show>
        <div class="alert-body">
          {{ $t('The event is free. No cancellation policies required.') }}
        </div>
      </b-alert>
      <b-alert v-else variant="danger" show>
        <div class="alert-body">
          {{ $t('Bookings already exist for this event. Therefore, this area can not be edited.') }}
        </div>
      </b-alert>
    </div>
    <div v-else>
      <b-alert v-if="!currentItem.has_bookings" variant="warning" show>
        <div class="alert-body">
          {{ $t('In this area you can define your cancellation policies. On the left hand, you specify the hours from when this cancellation policy should apply. On the right hand you define how much the cancellation fee is.') }}
        </div>
      </b-alert>
      <b-alert v-else variant="danger" show>
        <div class="alert-body">
          {{ $t('Bookings already exist for this event. Therefore, this area can not be edited.') }}
        </div>
      </b-alert>
      <div class="repeater-form" @submit.prevent="repeatCancellationCondition">
        <div v-for="(cancellation_condition, index) in currentItem.cancellation_conditions" :id="cancellation_condition.id"
             :key="index" class="position-relative" ref="cancellationConditions" >
          <b-row class="position-relative pr-5 align-items-end">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                size="md"
                class="btn-icon position-absolute position-right-0 position-bottom-0 mb-4 mr-1"
                @click="removeCancellationCondition(index)"
                :disabled="currentItem.has_bookings"
            >
              <feather-icon icon="Trash2Icon"/>
            </b-button>
            <b-col md="6">
              <validation-provider
                  #default="validationContext"
                  :name="$t('The hours from when this cancellation policy should apply')"
                  rules="required">
              <b-form-group  :label="$t('The hours from when this cancellation policy should apply')">
                <div class="input-height">
                <b-form-input
                    v-model="cancellation_condition.hours"
                    type="number"
                    :placeholder="$t('e.g 24 or 48')"
                    :disabled="currentItem.has_bookings"
                    :state="getValidationState(validationContext)"
                />
                
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                </div>
              </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">

              <validation-provider
                  #default="validationContext"
                  :name="$t('The hours from when this cancellation policy should apply')"
                  :rules="`required${currentItem.credits ? '|max_value:'+currentItem.credits : ''}`"
              >
              <b-form-group :label="$t('Cancellation fee in credits')">
              <div class="input-height">
                <b-form-input
                    v-model="cancellation_condition.credits"
                    type="number"
                    :placeholder="$t('Cancellation fee credits')"
                    :disabled="currentItem.has_bookings"
                    :state="getValidationState(validationContext)"
                />
              
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                </div>
              </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <hr>
        </div>
      </div>

      <b-row>
        <b-col md="6">
        </b-col>
        <b-col md="6" class="text-right">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary"
                    @click="repeatCancellationCondition"
                    :disabled="currentItem.has_bookings"
          >
            <feather-icon icon="PlusIcon" class="mr-25"/>
            <span>{{ $t('Add Cancellation condition') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTags,
  BFormTextarea,
  BFormTimepicker,
  BLink,
  BMedia,
  BMediaAside,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from '@validations'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import {heightTransition} from '@core/mixins/ui/transition'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormTags,
    BFormSelect,
    BFormTimepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  mixins: [heightTransition],

  props: {
    currentItem: {
      type: Object,
      default: () => {
      },
    },
  },

  data() {
    return {
      cancellationConditions: [],
      loaded: true,
      nextTodoId: 2,
    }
  },

  mounted() {
    // this.initTrHeight()
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.cancellationConditions.scrollHeight)
      })
    },
    repeatCancellationCondition() {
      if (!this.currentItem.cancellation_conditions) {
        this.currentItem.cancellation_conditions = [];
      }

      this.currentItem.cancellation_conditions.push({
        index: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
      })
    },
    removeCancellationCondition(index) {
      this.currentItem.cancellation_conditions.splice(index, 1)
    },
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null
    },
  },
}
</script>

<style lang="scss">
.input-height{  
  height: 70px;
}
</style>

