var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.$t('Location')}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Event location'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Event location')}},[_c('b-form-select',{attrs:{"id":"contract_easy","options":_vm.eventLocationOptions,"state":_vm.getValidationState(errors)},model:{value:(_vm.currentItem.eventlocation),callback:function ($$v) {_vm.$set(_vm.currentItem, "eventlocation", $$v)},expression:"currentItem.eventlocation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('eventlocation'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.eventlocation[0])))]):_vm._e()],1)]}}])})],1)],1),_c('b-row',[(_vm.currentItem.eventLocationOptions == 2)?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Event location'),"rules":{required: _vm.currentItem.eventLocationOptions === 2},"vid":"event_location"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Event location')}},[_c('b-form-select',{attrs:{"options":_vm.onlineOptions,"id":"onlineOptions","state":_vm.getValidationState(errors)},model:{value:(_vm.currentItem.online_options),callback:function ($$v) {_vm.$set(_vm.currentItem, "online_options", $$v)},expression:"currentItem.online_options"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('online_options'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.online_options[0])))]):_vm._e()],1)]}}],null,false,2770542858)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Personal generated Link'),"rules":{required: _vm.currentItem.eventLocationOptions === 2}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.currentItem.online_options == 2)?_c('b-form-group',{attrs:{"label":_vm.$t('Personal generated Link')}},[_c('b-form-input',{attrs:{"id":"online_link","placeholder":_vm.$t('Personal generated Link'),"state":_vm.getValidationState(errors)},model:{value:(_vm.currentItem.online_link),callback:function ($$v) {_vm.$set(_vm.currentItem, "online_link", $$v)},expression:"currentItem.online_link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('online_link'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.online_link[0])))]):_vm._e()],1):_vm._e()]}}])})],1)],1),(_vm.currentItem.eventlocation == 1)?_c('div',[_c('div',{staticClass:"mb-2"},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"value":"1","name":"handicapped"},model:{value:(_vm.currentItem.handicapped),callback:function ($$v) {_vm.$set(_vm.currentItem, "handicapped", $$v)},expression:"currentItem.handicapped"}},[_vm._v(" "+_vm._s(_vm.$t('Handicapped accessible'))+" ")])],1),_c('validation-provider',{attrs:{"name":_vm.$t('Street'),"rules":{required_if:{value:(_vm.currentItem.eventlocation == 1)}}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-1",attrs:{"label":_vm.$t('Street')}},[_c('b-form-input',{attrs:{"id":"street","state":_vm.getValidationState(errors),"placeholder":_vm.$t('Street')},model:{value:(_vm.currentItem.street),callback:function ($$v) {_vm.$set(_vm.currentItem, "street", $$v)},expression:"currentItem.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('street'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.street[0])))]):_vm._e()],1)]}}],null,false,2047682612)}),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"3","xl":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Postcode'),"rules":{required_if:{value:(_vm.currentItem.eventlocation == 1)}}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-1",attrs:{"label":_vm.$t('Postcode')}},[_c('b-form-input',{attrs:{"type":"number","maxlength":5,"minlength":5,"id":"postcode","placeholder":_vm.$t('Postcode'),"state":_vm.getValidationState(errors)},model:{value:(_vm.currentItem.postal),callback:function ($$v) {_vm.$set(_vm.currentItem, "postal", $$v)},expression:"currentItem.postal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('postal'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.postal[0])))]):_vm._e()],1)]}}],null,false,3228698183)})],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"9","xl":"9"}},[_c('validation-provider',{attrs:{"name":_vm.$t('City'),"rules":{required_if:{value:(_vm.currentItem.eventlocation == 1)}}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('City')}},[_c('b-form-input',{attrs:{"id":"city","placeholder":_vm.$t('City'),"state":_vm.getValidationState(errors)},model:{value:(_vm.currentItem.city),callback:function ($$v) {_vm.$set(_vm.currentItem, "city", $$v)},expression:"currentItem.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('city'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.city[0])))]):_vm._e()],1)]}}],null,false,1198642471)})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }