<template>
  <b-card :title="$t('Event gallery')">
    <vue-dropzone
        ref="refGalleryEl"
        id="dropzone"
        :options="dropzoneOptions"
        class="vue-dropzone"
        :src="currentItem.pictures"
        v-model="mediaFile"
        :destroyDropzone="false"
        v-on:vdropzone-removed-file="deleteGalleryItem"
        v-on:vdropzone-success="showSuccess"
    >
    </vue-dropzone>
  </b-card>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTags,
  BFormTextarea,
  BFormTimepicker,
  BLink,
  BMedia,
  BMediaAside,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from '@validations'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormTags,
    BFormSelect,
    BFormTimepicker,
    vueDropzone: vue2Dropzone,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    currentItem: {
      type: Object,
      default: () => {
      },
    },
  },

  data() {
    return {
      loaded: true,
      mediaFile: null,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_ROOT_API}events/${this.$route.params.eventid}/galleryupload`,
        paramName: 'files',
        thumbnailWidth: 160,
        thumbnailHeight: 120,
        maxFilesize: 1,
        headers: this.getGalleryUploadHeader(),
        addRemoveLinks: true,
        dictRemoveFile: this.$i18n.t('Delete image'),
        acceptedFiles: 'image/*',
        destroyDropzone: false,
        dictDefaultMessage: this.$i18n.t('Drop files here to upload'),
        dictFallbackMessage: this.$i18n.t('Your browser does not support drag\'n\'drop file uploads.'),
        dictFallbackText: this.$i18n.t('Please use the fallback form below to upload your files like in the olden days.'),
        dictInvalidFileType: this.$i18n.t('You can\'t upload files of this type.'),
        dictCancelUpload: this.$i18n.t('Cancel upload'),
        dictCancelUploadConfirmation: this.$i18n.t('Are you sure you want to cancel this upload?'),
        dictMaxFilesExceeded: this.$i18n.t('You can not upload any more files.'),
      },
    }

  },

  mounted() {
  },

  methods: {
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null
    },
    addGallery(files) {
      if (!files) {
        files = []
      }

      let self = this
      files.forEach(function (item) {
        let file = {
          name: item.name,
          size: item.size,
          type: item.type,
          id: item.id,
        }
        self.$refs.refGalleryEl.manuallyAddFile(file, item.url)
      })
    },

    showSuccess(file, response) {
      file.id = response.media.id
    },

    deleteGalleryItem(file) {
      let self = this
      let url = `/events/${this.$route.params.eventid}/galleryremove/${file.id}`
      this.$http.delete(url)
          .then(function (response) {
            self.$bvToast.toast(self.$i18n.t('Image deleted'), {
              title: self.$i18n.t('Success'),
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
            })
          })
          .catch((e) => {
            this.$bvToast.toast(this.$i18n.t('Image not deleted'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
            })
          })
    },

    getGalleryUploadHeader() {
      let token = localStorage.getItem('accessToken')
      return {
        'Authorization': 'Bearer ' + token
      }
    },
  },
}
</script>

<style>
</style>

