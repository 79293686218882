<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
          @submit.stop.prevent="handleSubmit(updateItem)"
          @reset.prevent="resetForm"
      >
        <b-row class="match-height">
          <b-col md="6">
            <card-event-image :current-item="currentItem"></card-event-image>
          </b-col>
        </b-row>

        <card-general-informations :current-item="currentItem"></card-general-informations>

        <b-row class="match-height">
          <b-col md="6">
            <card-host :current-item="currentItem"></card-host>
          </b-col>
          <b-col md="6">
            <card-location :current-item="currentItem"></card-location>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col md="6">
            <card-bookingtimes :current-item="currentItem" :edit-mode="true"></card-bookingtimes>
          </b-col>
          <b-col md="6">
            <card-payment :current-item="currentItem"></card-payment>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col md="6">
            <card-timeslots-fixed v-if="currentItem.booking_type ==1"  :current-item="currentItem"></card-timeslots-fixed>
            <card-timeslots-dynamic v-if="currentItem.booking_type ==2"  :current-item="currentItem"></card-timeslots-dynamic>
          </b-col>
          <b-col md="6">
            <card-cancellation-policy :current-item="currentItem"></card-cancellation-policy>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <card-event-gallery :current-item="currentItem" ref="cardEventGallery"></card-event-gallery>
          </b-col>
        </b-row>
        <b-card>
          <b-row>
            <b-col cols="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
              >
                {{ $t('Update event') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>

  </component>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BImg,
  BLink,
  BRow,
  BToast,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { mask } from 'vue-the-mask'
import CardGeneralInformations from "@/views/events/EventCards/CardGeneralInformations";
import CardHost from "@/views/events/EventCards/CardHost";
import CardLocation from "@/views/events/EventCards/CardLocation";
import CardBookingtimes from "@/views/events/EventCards/CardBookingtimes";
import CardPayment from "@/views/events/EventCards/CardPayment";
import CardTimeslotsFixed from "@/views/events/EventCards/CardTimeslotsFixed";
import CardTimeslotsDynamic from "@/views/events/EventCards/CardTimeslotsDynamic";
import CardCancellationPolicy from "@/views/events/EventCards/CardCancellationPolicy";
import CardEventImage from "@/views/events/EventCards/CardEventImage";
import CardEventGallery from "@/views/events/EventCards/CardEventGallery";

export default {
  components: {
    BForm,
    BButton,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    //Event cards
    CardGeneralInformations,
    CardHost,
    CardLocation,
    CardBookingtimes,
    CardPayment,
    CardTimeslotsFixed,
    CardTimeslotsDynamic,
    CardCancellationPolicy,
    CardEventImage,
    CardEventGallery,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      loaded: false,
      currentItem: {

        timeslots: [],
        timeslot_dynamic: []
      },
    }
  },

  mounted() {
    this.getItem()
  },

  methods: {
    getItem() {
      let url = `/events/${this.$route.params.eventid}`
      this.$http.get(url)
          .then(response => {
            this.currentItem = response.data
            this.$refs.cardEventGallery.addGallery(this.currentItem.gallery)
          })
          .catch(error => {
            this.loaded = false
          })
    },

    updateItem() {
      this.helperUpdateItem(
          `/events/${this.$route.params.eventid}`,
          this.currentItem
      )
    },
  },

}
</script>

<style>
@import '~@core/scss/vue/events/eventpictures.scss';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.quill-editor .ql-container {
  height: 250px;
}
.visible-row {
  overflow: visible !important;
}
</style>

