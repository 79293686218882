<template>
  <b-card :title="$t('Host')">
    <b-row>
      <b-col md="12">
        <validation-provider #default="{ errors }" :name="$t('Host')" rules="required">
          <b-form-group :label="$t('Host')">
            <b-form-input v-model="currentItem.host" :state="getValidationState(errors)" id="host"
              :placeholder="$t('Name of the Expert')" />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="validate && Object.keys(validation).includes('host')" class="text-danger">{{
            $t(validation.host[0]) }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="12">
        <b-form-group :label="$t('Organizer')">
          <b-form-input v-model="currentItem.organizer" id="organizer" :placeholder="$t('Name of the Organizer')" />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group :label="$t('Contact Person')">
          <b-form-input v-model="currentItem.contactperson" id="contactperson" :placeholder="$t('Contact Person')" />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTags,
  BFormTextarea,
  BFormTimepicker,
  BLink,
  BMedia,
  BMediaAside,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from '@validations'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormTags,
    BFormSelect,
    BFormTimepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    currentItem: {
      type: Object,
      default: () => {
      },
    },
    validation: {
      type: Object,
      default: () => {
      },
    },
    validate: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      loaded: true,
    }

  },

  mounted() {
  },

  methods: {
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null
    },
  },
}
</script>

<style>
</style>

