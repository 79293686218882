var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.$t('General Event informations')}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"value":"1","name":"status"},model:{value:(_vm.currentItem.status),callback:function ($$v) {_vm.$set(_vm.currentItem, "status", $$v)},expression:"currentItem.status"}},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")])],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Event Title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Event Title')}},[_c('b-form-input',{attrs:{"state":_vm.getValidationState(errors),"id":"title","placeholder":_vm.$t('Event Title')},model:{value:(_vm.currentItem.name),callback:function ($$v) {_vm.$set(_vm.currentItem, "name", $$v)},expression:"currentItem.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('name'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.name[0])))]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Event language'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Event language')}},[_c('b-form-select',{attrs:{"state":_vm.getValidationState(errors),"id":"language","options":_vm.languages,"text-field":"name","value-field":"code"},model:{value:(_vm.currentItem.language),callback:function ($$v) {_vm.$set(_vm.currentItem, "language", $$v)},expression:"currentItem.language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('language'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.language[0])))]):_vm._e()],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Event Type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Event Type')}},[_c('b-form-select',{attrs:{"options":_vm.eventTypesOptions,"placeholder":_vm.$t('Please select an Event Type'),"state":_vm.getValidationState(errors)},model:{value:(_vm.currentItem.event_type_id),callback:function ($$v) {_vm.$set(_vm.currentItem, "event_type_id", $$v)},expression:"currentItem.event_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('event_type_id'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.event_type_id[0])))]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label":_vm.$t('Agenda')}},[_c('b-form-input',{attrs:{"id":"street","placeholder":_vm.$t('Agenda')},model:{value:(_vm.currentItem.agenda),callback:function ($$v) {_vm.$set(_vm.currentItem, "agenda", $$v)},expression:"currentItem.agenda"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label":_vm.$t('Target Group')}},[_c('b-form-input',{attrs:{"id":"street","placeholder":_vm.$t('Target Group')},model:{value:(_vm.currentItem.target),callback:function ($$v) {_vm.$set(_vm.currentItem, "target", $$v)},expression:"currentItem.target"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Short Description'),"rules":"required|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Short Description')}},[_c('b-form-textarea',{attrs:{"rows":"6","state":_vm.getValidationState(errors),"placeholder":_vm.$t('Short Description')},model:{value:(_vm.currentItem.informations),callback:function ($$v) {_vm.$set(_vm.currentItem, "informations", $$v)},expression:"currentItem.informations"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.validate && Object.keys(_vm.validation).includes('informations'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.informations[0])))]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.charactersLeft)+" ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }